// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-0-perrosperdidos-js": () => import("./../../../src/pages/0perrosperdidos.js" /* webpackChunkName: "component---src-pages-0-perrosperdidos-js" */),
  "component---src-pages-aviso-legal-js": () => import("./../../../src/pages/aviso-legal.js" /* webpackChunkName: "component---src-pages-aviso-legal-js" */),
  "component---src-pages-blog-para-perros-js": () => import("./../../../src/pages/blog-para-perros.js" /* webpackChunkName: "component---src-pages-blog-para-perros-js" */),
  "component---src-pages-comprar-collar-kolyy-js": () => import("./../../../src/pages/comprar-collar-kolyy.js" /* webpackChunkName: "component---src-pages-comprar-collar-kolyy-js" */),
  "component---src-pages-dev-404-page-js": () => import("./../../../src/pages/dev-404-page.js" /* webpackChunkName: "component---src-pages-dev-404-page-js" */),
  "component---src-pages-eula-js": () => import("./../../../src/pages/eula.js" /* webpackChunkName: "component---src-pages-eula-js" */),
  "component---src-pages-guia-de-uso-js": () => import("./../../../src/pages/guia-de-uso.js" /* webpackChunkName: "component---src-pages-guia-de-uso-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-informacion-seguridad-js": () => import("./../../../src/pages/informacion-seguridad.js" /* webpackChunkName: "component---src-pages-informacion-seguridad-js" */),
  "component---src-pages-politica-cookies-js": () => import("./../../../src/pages/politica-cookies.js" /* webpackChunkName: "component---src-pages-politica-cookies-js" */),
  "component---src-pages-politica-de-privacidad-js": () => import("./../../../src/pages/politica-de-privacidad.js" /* webpackChunkName: "component---src-pages-politica-de-privacidad-js" */),
  "component---src-pages-razas-de-perro-js": () => import("./../../../src/pages/razas-de-perro.js" /* webpackChunkName: "component---src-pages-razas-de-perro-js" */),
  "component---src-pages-reserva-collar-kolyy-js": () => import("./../../../src/pages/reserva-collar-kolyy.js" /* webpackChunkName: "component---src-pages-reserva-collar-kolyy-js" */),
  "component---src-pages-terminos-y-condiciones-js": () => import("./../../../src/pages/terminos-y-condiciones.js" /* webpackChunkName: "component---src-pages-terminos-y-condiciones-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-doggipedia-post-js": () => import("./../../../src/templates/doggipediaPost.js" /* webpackChunkName: "component---src-templates-doggipedia-post-js" */)
}

